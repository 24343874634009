import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'
import { useState, useRef } from 'react'
import { Dialog } from '@headlessui/react'

const GalleryPage = ({data}) => {
  const entry = data.markdownRemark

  let [isOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState(null);
  let closeButtonRef = useRef(null);
  
  const handleImage = e => {
    setImage(entry.frontmatter.images[e.currentTarget.dataset.image].image.childImageSharp.gatsbyImageData);
    setIsOpen(true);
  }

	return (
    <div>
      <Dialog className="fixed z-30 inset-0 mx-auto" as="div" initialFocus={closeButtonRef} open={isOpen} onClose={() => setIsOpen(false)} >
      <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-90" />
        <div className="flex items-center justify-center px-6 lg:px-12 w-full h-full max-h-full">
          <div className="w-11/12 lg:w-9/12 h-full max-h-full relative bg-white px-6 lg:px-12">
            <div className="absolute z-50 top-0 right-0 pt-3 pr-3 group">
              <button ref={closeButtonRef} type="button" className="flex items-center px-4 py-2 bg-grey-e text-blue group-hover:bg-blue group-hover:text-white focus:outline-none" onClick={() => setIsOpen(false)}>
                <span className="font-verdana text-base text-blue group-hover:text-white font-bold">Close</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 inline-block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            {image ? <GatsbyImage image={image} alt="" style={{ width: "100%", height: "100%" }} imgStyle={{ objectFit: "contain" }} /> : ''}
          </div>
        </div>
      </Dialog>

      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <div className="w-full py-12 bg-blue">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center">
            <div className="w-8/12 lg:w-6/12 font-verdana">
              <h1 className="text-white font-bold text-3xl lg:text-5xl leading-tight">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 border-b-2 border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="columns-1 lg:columns-3 gap-12">
            {entry.frontmatter.images.map((entry, i) => {
              const childImage = getImage(entry.image)
                return (
                  <button type="button" data-image={i} onClick={handleImage} key={`child_${i}`} className="cursor-pointer group mb-12">
                    <GatsbyImage image={childImage} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                  </button>
            )})}
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default GalleryPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        images {
					image {
            childImageSharp {
              gatsbyImageData(width: 1280)
            }
          }
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`